import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React, { useEffect, useState } from "react"
import Layout from "../../components/_App/layout"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import * as convertStyled from "../../assets/css/convert-case.module.css"
import DownloadAndCopyButtons from "../../helpers/buttons"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import CopiedMessage from "../../helpers/CopiedMessage"

const parent = [{
  name: "Tools",
  link: 'tools',
}];

function JsonParser() {
  const [jsonInput, setJsonInput] = useState("");
  const [parsedJson, setParsedJson] = useState(null);
  const [error, setError] = useState(null);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [message, setMessage] = useState("");

  const handleJsonInputChange = (event) => {
    setJsonInput(event.target.value);
  };

  const parseJson = (e) => {
    handleJsonInputChange(e);
    try {
      const parsedData = JSON.parse(e.target.value);

      const removeWhiteSpaces = (obj) => {
        for (const key in obj) {
          if (typeof obj[key] === "object") {
            removeWhiteSpaces(obj[key]);
          } else if (typeof obj[key] === "string") {
            obj[key] = obj[key].replace(/\s+/g, " ");
          }
        }
      };

      removeWhiteSpaces(parsedData);

      setParsedJson(JSON.stringify(parsedData, null, 2));
      setError(null);
    } catch (err) {
      setError("Error parsing JSON");
      setParsedJson(null);
    }
  };

  useEffect(() => {
    setIsInputEmpty(jsonInput === "");
  }, [jsonInput])

  const showMessage = (msg) => {
    setMessage(msg);
  };

  return (
    <Layout>
      <Seo title="Parse JSON Online Tool"/>
      <Navbar/>
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>JSON Parser</h1>
            <div className="bar"></div>
          </div>
          <Breadcrumbs pageTitle="JSON Parser" parent={parent} />
          <div className="row align-items-center">
            <div className="row">
              <div className="col-lg-6">
                <textarea name="" value={jsonInput}  onChange={(e) => parseJson(e)} className={convertStyled.text_field} id="" cols="30" rows="10"/>
              </div>
              <div className="col-lg-6">
                <textarea name="" value={parsedJson} className={`${convertStyled.text_field}`} id="" cols="30" rows="10"/>
                <DownloadAndCopyButtons previewValue={parsedJson}  disabled={isInputEmpty} showMessage={showMessage}/>
                {message && (<CopiedMessage message={message} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default JsonParser
